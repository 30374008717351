<template>
  <div class="app">
    <el-dialog
      v-model="dialogVisible"
      title="添加跟进记录"
      width="480"
      :close-on-click-modal="false"
    >
      <div>
        <el-form :model="form" :rules="rules" ref="form" label-width="110px" :inline="true">
          <el-form-item label="跟进阶段:" prop="followStage">
            <select-clue
              v-model:clueStatus="form.followStage"
              placeholder="请选择"
              :type="'follow'"
              style="width: 300px; margin: 0"
            />
          </el-form-item>
          <el-form-item label="拜访方式:" prop="visitMethod">
            <el-radio-group v-model="form.visitMethod">
              <el-radio :label="item.value" v-for="item in followVisit">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="跟进内容:" prop="content">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入跟进内容"
              style="width: 300px; margin: 0"
              v-model="form.content">
            </el-input>
          </el-form-item>
          <el-form-item label="下次跟进时间:">
            <el-date-picker
              v-model="form.nextTime"
              type="datetime"
              size="small"
              value-format="YYYY-MM-DD HH:mm:ss"
              style="width: 300px; margin: 0"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeDialog" size="small">取消</el-button>
          <el-button type="primary"  size="small" @click="save">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { FOLLOW_STATUS,FOLLOW_VISIT } from "@/utils/commonData";
import { getFollow } from "@/api/newCrm.js";
import { nextTick } from "vue";

export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      followList: FOLLOW_STATUS.filter(item => item.label !== "全部"),
      followVisit:FOLLOW_VISIT,
      form: {},//表单数据
      rules: {
        followStage: [
          { required: true, message: '请选择跟进阶段', trigger: 'blur' },
        ],
        visitMethod: [
          { required: true, message: '请选择拜访方式', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入跟进内容', trigger: 'blur' },
        ],
        nextTime: [
          { required: true, message: '请选择下次跟进时间', trigger: 'blur' },
        ],
      },
      dialogVisible: false,
      id: null,
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {
    init(val){
      this.form.id = val*1
      this.$nextTick(()=>{
        this.dialogVisible = true
      })
    },
    closeDialog() {
      this.form = {}
      nextTick(() => {
        this.$refs['form'].clearValidate()
      })
      this.dialogVisible = false
    },
    save(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          getFollow(this.form).then(res => {
            if (res.data.msg == 'success') {
              this.closeDialog()
              this.$qzfMessage("跟进成功")
              this.$emit('success')
            }
            // this.options = res.data.data.list
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style scoped lang="less"></style>
