<template>
  <div class="app-container">
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick" style="width: 100%;">
      <el-tab-pane label="未成交" name="no">
      </el-tab-pane>
      <el-tab-pane label="已成交" name="yes">
      </el-tab-pane>
    </el-tabs>
    <!-- 筛选 -->
    <div class="content_select">
      <div style="display: flex;height: 25px;align-items: center;">
        <el-input placeholder="请输入客户名称/联系人/手机号" v-model="listQuery.infoFilter" style="width: 190px;margin-left:5px" size="small" @keyup.enter="getList" clearable/>
        <el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel" name="高级筛选">
          <el-form
            style="width: 400px;"
            label-width="110px"
          >
            <el-form-item label="录入日期："> 
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.createdAtBegin"
                type="date"
                placeholder="起始日期"
                clearable
                value-format="YYYY-MM-DD"
              /> &nbsp;-&nbsp;
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.createdAtEnd"
                type="date"
                placeholder="终止日期"
                clearable
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item label="分配日期："> 
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.assignAtBegin"
                type="date"
                placeholder="起始日期"
                clearable
                value-format="YYYY-MM-DD"
              /> &nbsp;-&nbsp;
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.assignAtEnd"
                type="date"
                placeholder="终止日期"
                clearable
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <div class="yesYw" v-if="activeName == 'yes'">
              <el-form-item label="成交日期：" v-if="activeName == 'yes'"> 
                <el-date-picker
                  style="width:110px;"
                  size="small"
                  v-model="listQuery.dealAtBegin"
                  type="date"
                  placeholder="起始日期"
                  clearable
                  value-format="YYYY-MM-DD"
                /> &nbsp;-&nbsp;
                <el-date-picker
                  style="width:110px;"
                  size="small"
                  v-model="listQuery.dealAtEnd"
                  type="date"
                  placeholder="终止日期"
                  clearable
                  value-format="YYYY-MM-DD"
                />
              </el-form-item>
              <el-form-item label="业务类型："> 
                <cascader-common
                  v-model:modelValue="listQuery.dealCategory"
                  placeholder="请选择业务类型"
                  :options="contractTypeOptions"
                  :casProps="casProps"
                  style="width: 234px; margin: 0"
                />
              </el-form-item>
              <el-form-item label="签单人员："> 
                <selectuser code="addCustomer" v-model:userId="listQuery.dealUserId" :multiples="false" style="width: 234px;margin: 0;" ></selectuser>
              </el-form-item>
            </div>
            <el-form-item label="跟进阶段："> 
              <select-clue
                v-model:clueStatus="listQuery.followStage"
                placeholder="请选择"
                :type="'follow'"
                :allType="true"
                style="width: 234px; margin: 0"
              />
            </el-form-item>
            <el-form-item label="客户来源："> 
              <el-select
                v-model="listQuery.customerSource"
                placeholder="请选择"
                style="width: 234px; margin: 0"
                filterable
                clearable
                size="small"
              >
                <el-option
                  v-for="item in customerStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所在地区："> 
              <districtList v-model:modelValue="listQuery.customerDistrict" style="width:234px;margin: 0;"></districtList>
            </el-form-item>
          </el-form>
        </search>
        <!-- <div v-if="activeName == 'no'">
          <span
            style="margin-left: 15px"
            class="textColor"
            @click="selectClueType('now')"
            :class="{ selected: selectedItem === 'now' }" 
            >今日要跟进</span
          >
          <span
            class="textColor"
            @click="selectClueType('new')"
            :class="{ selected: selectedItem === 'new' }" 
            >待首次跟进</span
          >
          <span
            class="textColor"
            @click="selectClueType('week')"
            :class="{ selected: selectedItem === 'week' }" 
            >一周内释放</span
          >
        </div> -->
      </div>
      <div style="display: flex;">
        <div v-if="activeName == 'no'">
          <el-dropdown style="margin:0 5px">
            <el-button type="primary" size="small" icon="CirclePlus" @click="addClue">
              新增<el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="toDaoRu">导入</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button
            type="success"
            @click="toAllocation"
            size="small"
            icon="Promotion"
            >分配</el-button
          >
          <el-button
            type="warning"
            @click="toReceive"
            size="small"
            icon="Finished"
            >释放</el-button
          >
          <el-button
            type="danger"
            @click="toDel"
            size="small"
            icon="Delete"
            style="margin-right: 10px;"
            >删除至回收站</el-button
          >
        </div>
        <colSetting
          name="我的线索"
          btnName="显示列"
          @arrangeInfo="getInfo"
          @load="infoLoading"
        />
      </div>
    </div>
    <!-- 列表 -->
    <div class="table_container">
      <el-table
        :data="clueAllList"
        style="width: 100%; margin-top: 10px"
        border
        :height="contentStyleObj"
        @selection-change="handleSelectClue"
        v-loading="listLoading"
        stripe
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" fixed />
        <el-table-column
          prop="customerName"
          label="客户名称"
          fixed
          min-width="200"
          align="left"
        >
          <template #default="scope">
            <span class="cosName" @click="toEdit(scope.row,'edit')">{{ scope.row.customerName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="customerContacter"
          label="客户联系人"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="customerContacterMobile"
          label="联系人手机"
          min-width="120"
          align="left"
        />
        <el-table-column
          prop="level"
          label="线索等级"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="status"
          label="线索状态"
          min-width="80"
          align="left"
        >
          <template #default="scope">
            {{ (culeStatusList.find(item => item.value == scope.row.status)).label }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="activeName == 'yes'"
          prop="dealUserName"
          label="签单人员"
          min-width="120"
          align="left"
        />
        <el-table-column
          v-if="activeName == 'yes'"
          prop="totalAmount"
          label="合同总金额"
          min-width="100"
          align="left"
        />
        <el-table-column
          v-if="activeName == 'yes'"
          prop="dealTime"
          sortable
          label="成交时间"
          min-width="80"
          align="left"
        />
        <el-table-column
          prop="followUserName"
          label="跟进负责人"
          min-width="100"
          align="left"
        />
        <el-table-column
          :prop="(arrangePropInfo.find(item => item.lable == val)).value"
          min-width="120"
          align="left"
          v-for="(val,i) in arrangeInfo"
          :sortable="val == '最近跟进时间' || val == '下次跟进时间'|| val == '最近分配时间'|| val == '上次释放时间'|| val == '即将释放日期'? true:false"
        >
          <template #header>
            <span>{{val}}</span>
          </template>
          <template #default="scope" v-if="val == '客户来源'">
            <span v-if="scope.row.customerSource != 0">
              {{ (customerStatusList.find(item => item.value == scope.row.customerSource)).label }}
            </span>
            <span v-else>

            </span>
          </template>
          <template #default="scope" v-if="val == '最近跟进记录'">
            <span @click="toEdit(scope.row,'follow')" style="cursor: pointer;">
              {{ scope.row.lastFollowContent }}
            </span>
          </template>
          <template #default="scope" v-if="val == '备注'">
            <el-tooltip effect="dark" placement="top-start" :content="scope.row.remark">
              <span style="cursor: pointer;">{{ scope.row.remark }}</span>
            </el-tooltip>
          </template>
          <template #default="scope" v-if="val == '最近跟进记录'">
            <el-tooltip effect="dark"  placement="top-start" :content="scope.row.lastFollowContent">
              <span style="cursor: pointer;">{{ scope.row.lastFollowContent }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="inputClerkName"
          label="录入人"
          min-width="146"
          align="left"
        />
        <el-table-column
          prop="createdTime"
          label="录入时间"
          min-width="120"
          sortable
          align="left"
        />
        <el-table-column
          label="操作"
          align="center"
          :width="activeName == 'no' ? 230 : 150"
          fixed="right"
        >
          <template #default="scope">
            <el-button
              type="text"
              size="small"
              v-if="activeName == 'no'"
              @click="toDell(scope.row)"
              style="margin: 0;padding: 5px 2px"
              icon="CircleCheck"
            >成交</el-button> 
            <el-button
              v-if="activeName == 'no'"
              type="text"
              size="small"
              @click="toFollow(scope.row)"
              style="margin: 0;padding: 5px 2px"
              icon="Compass"
            >跟进</el-button> 
            <el-button
              v-if="activeName == 'no' && !scope.row.protected "
              type="text"
              size="small"
              @click="toProtect(scope.row,'yes')"
              style="margin: 0;padding: 5px 2px"
              icon="Opportunity"
            >保护</el-button>
            <el-button
              v-if="activeName == 'yes'"
              type="text"
              size="small"
              @click="toView(scope.row)"
              style="margin: 0;padding: 5px 2px"
              icon="View"
            >查看</el-button>
            <el-button
              v-if="activeName == 'no' && scope.row.protected"
              type="text"
              size="small"
              @click="toProtect(scope.row,'no')"
              style="margin: 0;padding: 5px 2px"
              icon="Opportunity"
            >取消保护</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page_container">
      <qzf-pagination
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>

  <!-- 跟进分配弹窗 -->
  <allocation ref="allocation" @success="getList"></allocation>
  <!-- 新增弹窗 -->
  <addfrom ref="addfrom"  @success="getList"></addfrom>
  <!-- 编辑弹窗 -->
  <editfrom ref="editfrom" :dis="activeName == 'no' ? false : true" @success="getList"></editfrom>
  <!-- 导入 -->
  <daoru ref="daoru" @success="getList"></daoru>
  <!-- 跟进 -->
  <followclue ref="followClue" @success="getList"></followclue>
  <!-- 成交线索 -->
  <dealclue ref="dealclue" :dealDisabled="activeName == 'no' ? false : true" @success="getList"></dealclue>
</template>

<script>
import { CULE_STATUS,FOLLOW_STATUS,CUSTOMER_STATUS } from "@/utils/commonData";
import addfrom from "./components/addForm.vue"//新增线索抽屉
import editfrom from "./components/editFrom.vue"//编辑线索抽屉
import allocation from "./components/allocation.vue"//分配组件
import daoru from "./components/daoRu.vue"//导入组件
import dealclue from "./components/dealClue.vue"//成交线索抽屉
import followclue from "./components/followClue.vue"//跟进线索
import addressList from "@/components/Screening/address";
import districtList from "@/components/Screening/district";
import selectBusiness from "../../crm/components/selectBusiness.vue";
import { myList,getRelease,getRecycle,protect,dealInfo,contractTypeList } from "@/api/newCrm.js";

export default {
  name: '',
  components: {
    addfrom,
    allocation,
    editfrom,
    daoru,
    addressList,
    selectBusiness,
    followclue,
    dealclue,
    districtList
  },
  props: {},
  data () {
    return {
      casProps:{
        expandTrigger: "hover",
        label: "name",
        value: "id",
      },
      activeName: 'no',
      selectedItem: '',
      listQuery: {
        page: 1,
        limit: 20,
        infoFilter: '',
        followUserId: null,//跟进负责人
        createdAtBegin: '',
        createdAtEnd: '',
        assignAtBegin: '',
        assignAtEnd: '',
        followStage: '',
        customerDistrict: '',
        customerSource: null,
        deal: 1,
        dealCategory: null,
        dealAtEnd: '',
        dealAtBegin: '',
        followFilter: 0,
        dealUserId: null,
      },
      total: 0,
      clueAllList: [
      ],//未成交列表数据
      clueYesList: [],//未成交列表数据
      culeStatusList: CULE_STATUS,//线索状态
      followStatusList: FOLLOW_STATUS,//跟进状态
      customerStatusList: CUSTOMER_STATUS,//客户来源
      infoLoading: false,
      arrangePropInfo: [//列表列对应的prop数据
        { lable: "跟进阶段", value: "followStage" },
        { lable: "最近跟进时间", value: "lastFollowTime" },
        { lable: "最近跟进记录", value:"lastFollowContent" },
        {lable:"下次跟进时间",value:"nextFollowTime"},
        {lable:"所在地区",value:"customerDistrictCN"},
        {lable:"办公地址",value:"customerAddress"},
        {lable:"成立日期",value:"incorporationTime"},
        {lable:"客户来源",value:"customerSource"},
        {lable:"备注",value:"remark"},
        {lable:"最近分配时间",value:"assignTime"},
        {lable:"上次释放时间",value:"lastReleaseTime"},
        {lable:"即将释放日期",value:"nextReleaseTime"},
      ],
      arrangeInfo: [],
      ids: [],
      contractTypeOptions:[],//业务类型
    }
  },
  computed: {},
  watch: {
    arrangeInfo: {  
      handler(val, oldVal) {  
        this.arrangeInfo = val
      },  
      immediate: true, 
      deep: true  
    }  
  },
  created () {
    this.contentStyleObj = this.$getHeight(265);
    this.getBussList()
    this.getList()
  },
  mounted () {},
  methods: {
    getBussList(){
      contractTypeList({ contractType: '100' }).then((res) => {
        this.contractTypeOptions = res.data.data.list;
      });
    },
    // 获取列表数据
    getList() {
      if(this.activeName == 'no' ){//未成交
        this.listQuery.deal = 1
      }else{//已成交
        this.listQuery.deal = 2
      }
      this.listLoading = true
      myList({
        ...this.listQuery,
        customerSource: this.listQuery.customerSource || undefined,
      }).then(res => {
        if(res.data.msg == 'success'){
          this.listLoading = false
          this.clueAllList = res.data.data.data || []
          this.total = res.data.data.total
        }
      })
    },

    // tab切换
    handleClick(tab){
      this.listQuery = {
        followUserId: null,//跟进负责人
        createdAtBegin: '',
        createdAtEnd: '',
        assignAtBegin: '',
        assignAtEnd: '',
        followStage: '',
        customerDistrict: '',
        customerSource: null,
        dealCategory:null,
        dealAtEnd: '',
        dealAtBegin: '',
        followFilter: 0,
        page: 1,
        limit: 20,
        dealUserId: null,
      }
      this.selectedItem = ''
      this.activeName = tab.props.name
      this.getList()
    },

    // 线索操作展示列表数据
    selectClueType(item) {  
      this.selectedItem = item; // 更新选中的项 
      if(item == 'now'){
        this.listQuery.followFilter = 1
      }else if(item == 'new'){
        this.listQuery.followFilter = 2
      }else{
        this.listQuery.followFilter = 3
      }
      this.getList(); // 调用getList方法，如果有的话  
    }, 

    // 重置
    cancel(){
      this.listQuery = {
        followUserId: null,//跟进负责人
        createdAtBegin: '',
        createdAtEnd: '',
        assignAtBegin: '',
        assignAtEnd: '',
        followStage: '',
        customerDistrict: '',
        customerSource: null,
        dealCategory:null,
        dealAtEnd: '',
        dealAtBegin: '',
        followFilter: 0,
        page: 1,
        limit: 20,
        dealUserId: null,
      }
      this.getList()
    },
    // 列表勾选
    handleSelectClue(val) {
      this.ids = []
      let list = val;
      list.map((v,i)=>{
        this.ids.push(v.id)
      })
    },
    
    // 显示列操作
    getInfo(e) {
      this.arrangeInfo = e;
    },

    infoLoading(){
      // this.listLoading = true
    },

    // 新增线索
    addClue(){
      if(this.activeName == 'no'){
        this.$refs.addfrom.init()
      }else{

      }
    },

    // 分配
    toAllocation(){
      if(this.ids.length == 0){
        this.$message.warning('请至少选择一条线索分配');
        return
      }
      this.$refs.allocation.init(this.ids)
    },

    // 查看
    toView(val){
      this.$refs.dealclue.init(val,'view')
    },

    // 释放
    toReceive(){
      if(this.ids.length == 0){
        this.$message.warning('请至少选择一条线索释放');
        return
      }
      let message = '已选择'+ this.ids.length +'家客户，确认释放？释放后，' + this.ids.length +'个客户回归线索池，且为您交出'+ this.ids.length +'个领取或分配的线索池客户名额';
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        getRelease({clueIds:this.ids}).then(res => {
          if (res.data.msg == 'success') {
            this.$qzfMessage("释放成功")
            this.getList()
          }
          // this.options = res.data.data.list
        })
      })
    },

    // 删除至回收站
    toDel(){
      if(this.ids.length == 0){
        this.$message.warning('请至少选择一条线索删除');
        return
      }
      this.$confirm('确定要将客户删除至回收站?确定后线索池、我的线索将不再查询到,可在回收站对数据进行恢复。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getRecycle({clueIds:this.ids}).then(res => {
          if (res.data.msg == 'success') {
            this.$qzfMessage("删除成功")
            this.getList()
          }
        })
      })
    },

    // 点击客户名称编辑
    toEdit(row,type){
      this.$refs.editfrom.init(row,type)
    },

    // 点击导入
    toDaoRu(){
      this.$refs.daoru.init()
    },

    // 跟进
    toFollow(row){
      this.$refs.followClue.init(row.id)
    },

    // 保护/取消保护
    toProtect(row,type){
      if(type == 'yes'){
        protect({clueIds:[row.id],protect:true}).then(res => {
          if (res.data.msg == 'success') {
            this.$qzfMessage("已保护")
            this.getList()
          }
        })
      }else{
        protect({clueIds:[row.id],product:false}).then(res => {
          if (res.data.msg == 'success') {
            this.$qzfMessage("取消保护",2)
            this.getList()
          }
        })
      }
    },

    // 成交
    toDell(row){
      this.$refs.dealclue.init(row)
    },
  }
}
</script>

<style scoped lang="less">
.content_select{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textColor{
  margin: 0 6px;
  cursor: pointer;
  font-size: 14px;
}
.selected {  
  color: #17A2B8;  
} 
.drawerHead{
  display: flex;
  justify-content: space-between;
}
.cosName{
  cursor: pointer;
}
.cosName:hover{
  color: #17A2B8;  
}
.page_container{
  float: right;
  margin-top: 10px;
}
.yesYw :deep(.el-cascader--small){
  width: 234px!important;
}
</style>
