<template>
  <div class="app-container">
    <div class="big_box2 table_height" :style="{maxHeight:contentStyleObj}" v-loading="loading">
      <!-- 顶部 -->
      <div class="name_box" :class="{'special':invoiceType !='1' && invoiceType !='4'}">
          <h5 @click.stop="showbox" id="hello">
            {{invoiceTypeName}}
            <span></span>
          </h5>
          <ul class="hide_sel" :class="{amod_ad:showtitle}">
            <li @click.stop="switchInvoice(item)" v-for="(item,id) in invoiceTypes" :key="id">{{item.name}}</li>
          </ul>
        </div>
      <out ref="addInvoice" @success="changeInvoiceType" :invoiceType="invoiceType" v-if="invoiceTypeName == '增值税专用发票' || invoiceTypeName == '增值税电子专用发票' || invoiceTypeName == '增值税普通发票' || invoiceTypeName == '增值税电子普通发票' || invoiceTypeName == '无票收入'"></out>
      <!-- <zzsptfpjp v-if="invoiceTypeName == '增值税普通发票（卷票）'"></zzsptfpjp>
      <zzsdzptfplkys v-if="invoiceTypeName == '增值税电子普通发票（旅客运输）'"></zzsdzptfplkys>
      <zzsptfptxf v-if="invoiceTypeName == '增值税普通发票（通行费）'"></zzsptfptxf>
      <jdcxstyfp v-if="invoiceTypeName == '机动车销售统一发票'"></jdcxstyfp>
      <gstyjdfp v-if="invoiceTypeName == '国税通用机打发票'"></gstyjdfp>
      <escxstyfp v-if="invoiceTypeName == '二手车销售统一发票'"></escxstyfp>
      <tydefp v-if="invoiceTypeName == '通用定额发票'"></tydefp>
      <wpsr v-if="invoiceTypeName == '无票收入'"></wpsr> -->
    </div>
  </div>
</template>

<script>
import out from "./invoiceComponents/out.vue";
import zzsptfpjp from "./zzsptfpjp.vue"
import zzsdzptfptxf from "./zzsdzptfptxf.vue"
import zzsdzptfplkys from "./zzsdzptfplkys.vue"
import jdcxstyfp from "./jdcxstyfp.vue"
import gstyjdfp from "./gstyjdfp.vue"
import escxstyfp from "./escxstyfp"
import tydefp from "./tydefp.vue"
import wpsr from "./wpsr.vue"

import { outInvoiceInfo } from '@/api/invoice.js'

export default {
  name:'addIncome',
  components:{
    out,
    zzsptfpjp, //增值税普通发票（卷票）
    zzsdzptfptxf, //增值税电子普通发票（通行费）
    zzsdzptfplkys, //增值税电子普通发票（旅客运输）
    jdcxstyfp, //机动车销售统一发票
    gstyjdfp, //国税通用机打发票
    escxstyfp, //二手车销售统一发票
    tydefp, //通用定额发票
    wpsr, //无票收入
    // 商业发票 todo
  },
  data() {
    return{
        invoiceTypeName: "增值税专用发票", 
        invoiceType: "1", 
        invoiceTypes: [
        {
          id: "1",
          name: "增值税专用发票"
        },
        {
          id: "2",
          name: "增值税普通发票"
        },
        {
          id: "3",
          name: "无票收入"
        },
        {
          id: "4",
          name: "增值税电子专用发票"
        },
        {
          id: "5",
          name: "增值税电子普通发票"
        }, 
        // {
        //   id: "5",
        //   name: "增值税普通发票（卷票）"
        // },
        // {
        //   id: "6",
        //   name: "增值税电子普通发票（通行费）"
        // },
        // {
        //   id: "7",
        //   name: "机动车销售统一发票"
        // },
        // {
        //   id: "8",
        //   name: "国税通用机打发票"
        // },
        // {
        //   id: "9",
        //   name: "二手车销售统一发票"
        // },
        // {
        //   id: "10",
        //   name: "商业发票"
        // },
        // {
        //   id: "11",
        //   name: "无票收入"
        // },
        // {
        //   id: "12",
        //   name: "增值税电子普通发票（旅客运输）"
        // },
        // {
        //   id: "13",
        //   name: "通用定额发票"
        // },
      ],
      showtitle: false,
      value:'',
      contentStyleObj:{}, //高度变化
      loading:false,
    }
  },
  created(){
    this.contentStyleObj=this.$getHeight(140)
  },
  mounted () {
    this.init()
  },
  methods:{
    init(){
      let id = this.$store.getters["commons/params"].addOutInvoiceId
      if(id){
        this.loading = true
        outInvoiceInfo({id: id}).then(res=>{
          this.loading = false
          if(res.data.msg != "success"){
            return
          }
          this.info = res.data.data.info
          //处理发票type
          this.invoiceType = this.info.invoiceType
          this.invoiceTypes.map(v=>{
            if(this.invoiceType == v.id){
              this.invoiceTypeName = v.name
            }
          })
          this.$refs.addInvoice.init(this.info)
        })
      }else{
        this.invoiceType = "1"
        this.invoiceTypeName = "增值税专用发票"
        this.$refs.addInvoice.init()
      }
    },
    switchInvoice(item) {
      this.invoiceTypeName = item.name;
      this.invoiceType = item.id;
      this.showtitle = false;
    },
    showbox() {
      this.showtitle = true;
    },
    changeInvoiceType(e){
      this.invoiceType = e
      this.invoiceTypes.map(v=>{
        if(this.invoiceType == v.id){
          this.invoiceTypeName = v.name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.big_box2 {
   width: 1250px;
  padding: 20px 40px;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  // margin: 30px 0 0 30px;
  max-height: 750px;
  overflow-y: auto;
  position: relative;
  border:1px solid #ddd;
  .arrow_btn {
    width: 20px;
    height: 60px;
    text-align: center;
    .two_btn {
      font-size: 20px;
      color: #f15a24;
      line-height: 60px;
      background: #eee;
    }
    .arrow_left {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .arrow_right {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .top_title {
    width: 100%;
    margin: 0 auto;
    h5 {
      font-size: 30px;
      color: #333;
      text-align: center;
      font-weight: 500;
      margin-bottom: 36px;
    }
  }
  .name_box {
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
    margin-bottom: 10px;
    position: relative;
    .hide_sel {
      overflow: auto;
      width: 260px;
      height: 0;
      background: #fff;
      z-index: 9;
      position: absolute;
      // top: 0;
      left: 50%;
      transform: translateX(-44%);
      line-height: 30px;
      font-size: 14px;
      color: #333;
      text-align: left;
      li {
        cursor: pointer;
      }
      li:hover {
        color: #008000;
      }
    }

    h5 {
      display: inline-block;
      margin: 0 auto;
      font-size: 20px;
      color: #008000;
      text-align: center;
      font-weight: 400;
      border-bottom: 4px double #008000;
      line-height: 40px;
      position: relative;
      padding: 0 28px 0 4px;
      cursor: pointer;
      span {
        position: absolute;
        top: 13px;
        right: 3px;
        width: 0;
        height: 0;
        border-bottom: 16px solid #008000;
        border-left: 16px solid transparent;
        cursor: pointer;
      }
    }
  }
  .top_input {
    width: 30%;
    h6 {
      float: left;
      width: 60px;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      line-height: 36px;
      margin-right: 10px;
    }
  }
  .top_input2 {
    width: 30%;
    margin: 0 auto;
    position: relative;
    h6 {
      float: left;
      width: 60px;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      line-height: 28px;
      margin-right: 10px;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .content {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #008000;
    margin-top: 10px;
    border-right: none;
  }
  .grid-content {
    position: relative;
    min-height: 38px;
    font-size: 14px;
    color: #666;
    line-height: 38px;
    text-align: center;
  }
  .row_box2 {
    width: 100%;
    height: 154px;
  }
  .height_font {
    width: 100%;
    height: 152px;
    margin: 0 auto;
    text-align: center;
    p {
      width: 20px;
      margin: 0 auto;
      height: 100%;
      line-height: 28px;
      padding-top: 22px;
    }
  }
  .list_info {
    line-height: 24px;
    &.red li {
      border-bottom: 1px solid #d56400 !important;
    }
    li {
      position: relative;
      line-height: 38px;
      height: 38px;
      font-size: 14px;
      color: #333;
      border-bottom: 1px solid #008000;

      &:last-child {
        border-bottom: none;
      }
      input {
        width: 100%;
        margin: 0 auto;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #333;
        background: none;
        border: none;
        padding: 0 5px;
      }
    }
  }
  .weight {
    font-weight: 700;
  }
  .weight {
    li {
      font-weight: 600 !important;
    }
  }
  .star {
    color: #f15a24;
    height: 12px;
    font-size: 18px;
    display: inline-block;
    z-index: 999;
  }
  .input_box {
    width: 100%;
    height: 152px;
    font-size: 14px;
    color: #666;
    // line-height: 38px;
    text-align: center;
  }
  .top_font {
    border-bottom: 1px solid #008000;
    line-height: 38px;
    height: 39px;
    font-size: 14px;
    color: #333;
  }
  .item_box {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    position: relative;
    .close {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      right: -32px;
      top: 9px;
      font-size: 20px;
      color: #ff1818;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    .add {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      left: -28px;
      top: 9px;
      font-size: 16px;
      color: #f15a24;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    input {
      width: 100%;
      margin: 0 auto;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      color: #333;
      background: none;
      border: none;
      padding: 0 5px;
    }
  }
  .item_list {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    position: relative;
    .add {
      position: absolute;
      right: -38px;
      top: 10px;
      width: 1.8em;
      height: 1.8em;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: #f15a24;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    .bg-purple {
      line-height: 38px;
      height: 38px;
      font-size: 14px;
      color: #333;
      border-bottom: 1px solid #008000;
      // position: relative;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .bg-purple {
    text-align: center;
  }
  .bottom_num {
    width: 20%;
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    font-weight: normal;
    line-height: 36px;
  }
  .total {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    border-bottom: 1px solid #008000;
    line-height: 38px;
    height: 38px;
    font-size: 14px;
    color: #333;
  }
 .el-row {
  width: 100%;
  }
 .el-col {
  border-right: 1px solid #008000;
  }
  .special h5 {
    color: #d56400 !important;
    border-bottom: 4px double #d56400;
    span {
      border-bottom: 16px solid #d56400;
    }
  }
  .special .el-col {
    border-right: 1px solid #d56400;
  }
  .special .content {
    border: 1px solid #d56400;
    border-right: none;
  }
  .special .list_info li {
    border-bottom: 1px solid #d56400;
    &:last-child {
      border-bottom: none;
    }
  }
  .special .textarea {
    border-bottom: 1px solid #d56400;
  }
  .special .top_font {
    border-bottom: 1px solid #d56400;
  }
  .special .item_list {
    border-top: 1px solid #d56400;
  }
  .special .item_box {
    border-top: 1px solid #d56400;
  }
  .special .total {
    border-top: 1px solid #f15a24;
  }
}
.amod_ad {
  padding: 10px;
  animation: ad 0.3s 0s both;
  border: 1px solid #ccc;
  height: auto !important;
}
@-webkit-keyframes ad {
  0% {
    height: 0;
  }

  100% {
    height: 200px;
  }
}
</style>