<template>
  <div class="app">
    <!-- 新增成交线索弹窗 -->
    <el-drawer
      v-model="addDrawer"
      :close-on-click-modal="true"
      size="40%"
    >
      <template #header>
        <div class="drawerHead">
          <div>
            成交线索
          </div>
          <div>
            <el-button
              :loading="saveReqLoading"
              type="primary"
              @click="saveCule"
              size="small"
              v-if="!dealDisabled"
              style="margin-right: 5px"
              >保存</el-button
            >
          </div>
        </div>
      </template>
      <template #default>
        <el-form :model="form" :rules="rules" ref="form" label-width="120px" :inline="true">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item name="1">
              <template #title>
                <div class="formTitle">
                  基础信息
                </div>
              </template>
              <el-form-item label="客户名称:" prop="customerName">
                <el-input v-model="form.customerName" size="small" class="w200" disabled />
              </el-form-item>
              <el-form-item label="客户编码:">
                <el-input v-model="form.customerCode"  size="small" class="w200" clearable :disabled="dealDisabled || dealType == 'yes'" />
              </el-form-item>
              <el-form-item label="纳税人资格:" prop="taxpayerRight">
                <el-select
                  v-model="form.taxpayerRight"
                  placeholder="请选择纳税人资格"
                  style="width: 200px;margin: 0;"
                  filterable
                  size="small"
                  :disabled="dealDisabled || dealType == 'yes'" 
                >
                  <el-option
                    v-for="item in taxpayerList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="服务类型:" prop="serviceType">
                <el-radio-group v-model="form.serviceType" @change="changeType"  class="w200" :disabled="dealDisabled || dealType == 'yes'" >
                  <el-radio :label="1">代账</el-radio>
                  <el-radio :label="2">增值</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="客户联系人:" prop="customerContacter">
                <el-input v-model="form.customerContacter"  size="small" class="w200" clearable :disabled="dealDisabled || dealType == 'yes'" />
              </el-form-item>
              <el-form-item label="联系人手机:" prop="customerContacterMobile">
                <el-input v-model="form.customerContacterMobile"  size="small" class="w200" clearable :disabled="dealDisabled || dealType == 'yes'" />
              </el-form-item>
              <el-form-item label="合同总金额:" prop="totalAmount">
                <el-input v-model="form.totalAmount"  size="small" class="w200" disabled />
              </el-form-item>
              <el-form-item label="签单人员:" prop="dealUserId">
                <selectuser code="addCustomer" v-model:userId="form.dealUserId" :multiples="false" style="width: 200px;margin: 0;" :disabled="dealDisabled"></selectuser>
              </el-form-item>
              <el-form-item label="签订日期:">
                <el-date-picker
                  style="width:200px;margin: 0;"
                  size="small"
                  v-model="form.dealAt"
                  type="date"
                  clearable
                  :disabled="dealDisabled"
                  value-format="YYYY-MM-DD"
                />
              </el-form-item>
              <el-form-item label="备注:">
                <el-input v-model="form.remark"  size="small" class="w200" clearable :disabled="dealDisabled" />
              </el-form-item>
              <el-form-item label="老板手机号:" prop="bossTel">
                <el-input v-model="form.bossTel"  size="small" class="w200" clearable :disabled="dealDisabled || dealType == 'yes'" />
              </el-form-item>
            </el-collapse-item>
            <el-collapse-item name="2" class="ht">
              <template #title>
                <div class="formTitle margin20">
                  合同信息
                </div>
              </template>
              <div>
                <table class="content" cellspacing="0" width="100%">
                  <tr class="top_bg center">
                    <td style="width: 5%;height: 40px;text-align: center;" >操作</td>
                    <td style="width: 10%;height: 40px;text-align: center;"><span style="color: red;">*</span>业务类型</td>
                    <td style="width: 10%;height: 40px;text-align: center;"><span style="color: red;">*</span>开始日期</td>
                    <td style="width: 10%;height: 40px;text-align: center;"><span style="color: red;">*</span>结束日期</td>
                    <td style="width: 10%;height: 40px;text-align: center;">收款方式</td>
                    <td style="width: 5%;height: 40px;text-align: center;">收款周期</td>
                    <td style="width: 5%;height: 40px;text-align: center;">赠送月份</td>
                    <td style="width: 5%;height: 40px;text-align: center;"><span style="color: red;">*</span>月服务费</td>
                    <td style="width: 5%;height: 40px;text-align: center;">账本费</td>
                    <td style="width: 5%;height: 40px;text-align: center;"><span style="color: red;">*</span>费用类型</td>
                    <td style="width: 10%;height: 40px;text-align: center;">合同优惠</td>
                    <td style="width: 10%;height: 40px;text-align: center;"><span style="color: red;">*</span>应收金额</td>
                    <td style="width: 5%;height: 40px;text-align: center;">备注</td>
                    <td style="width: 5%;height: 40px;text-align: center;">合同编码</td>
                  </tr>
                  <tr
                    class="centers"
                    v-for="(item, index) in form.contract"
                    :key="index"
                    v-if="form.contract.length > 0"
                  >
                    <td style="width: 5%;">
                      <div style="display: flex;align-items: center;height: 20px;line-height: 20px;justify-content: center;">
                        <el-icon color="#409efc" style="margin-right: 6px;cursor: pointer;" :disabled="!dealDisabled || dealType == 'yes'" @click="addContract(form.contract)"><Plus /></el-icon> |
                        <el-icon color="#409efc" style="margin-left: 6px;cursor: pointer;" :disabled="!dealDisabled || dealType == 'yes'" @click="delContract(form.contract, index)"><Minus /></el-icon>
                      </div>
                    </td>
                    <!-- 业务类型 -->
                    <td style="width: 10%">
                      <cascader-common
                        v-model:modelValue="item.businessTypeId"
                        placeholder="请选择业务类型"
                        :options="contractTypeOptions"
                        :disabled="dealDisabled"
                        :casProps="casProps"
                        style="width: 190px"
                      />
                    </td>
                    <!-- 开始日期 -->
                    <td style="width: 10%">
                      <el-date-picker
                        style="width:100px;"
                        size="small"
                        v-model="item.contractStartTime"
                        type="date"
                        :disabled-date="firstDisabledDate"
                        placeholder="请选择"
                        :disabled="dealDisabled"
                        clearable
                        value-format="YYYY-MM-DD"
                        @change="changeCycle(item)"
                      />
                    </td>
                    <!-- 结束日期 -->
                    <td style="width: 10%">
                      <el-date-picker
                        style="width:100px;"
                        size="small"
                        v-model="item.contractEndTime"
                        type="date"
                        placeholder="请选择"
                        :disabled="dealDisabled || (item.paymentCycle != '一次性' && item.paymentCycle != '') "
                        clearable
                        :disabled-date="lastDisabledDate"
                        value-format="YYYY-MM-DD"
                        @change="changeCycle(item)"
                      />
                    </td>
                    <!-- 收款方式 -->
                    <td style="width: 10%">
                       <select-common placeholder="请选择" 
                        v-model:modelValue="item.paymentCycle" 
                        :options="cycleOptions" 
                        style="width: 75px" 
                        :disabled="dealDisabled"
                        @selectChange="changeCycle(item)"
                      />
                      <!-- @change="changeCycle(item,index)"  -->
                    </td>
                    <!-- 收款周期 -->
                    <td style="width: 5%">
                      <qzf-input style="width: 70px" v-model:num="item.paymentNum" :disabled="dealDisabled || item.paymentCycle == '一次性'" @blur="changeCycle(item)" />
                    </td>
                    <!-- 赠送月份 -->
                    <td style="width: 5%">
                      <qzf-input style="width: 70px" v-model:num="item.giveNum" :disabled="dealDisabled" @blur="changeCycle(item)" />
                    </td>
                    <!-- 月服务费 -->
                    <td style="width: 5%">
                      <qzf-input style="width: 70px" v-model:num="item.serviceCharge" :disabled="dealDisabled" @blur="changeCycle(item)" />
                    </td>
                    <!-- 账本费 -->
                    <td style="width: 5%">
                      <qzf-input style="width: 70px" v-model:num="item.accountBookFee" :disabled="dealDisabled" @blur="changeCycle(item)" />
                      <!-- <el-input
                        size="small"
                        style="width: 70px"
                        v-model="item.accountBookFee"
                        :disabled="dealDisabled"
                        @blur="changeAccount(item,index)"
                      /> -->
                    </td>
                    <!-- 费用类型 -->
                    <td style="width: 5%">
                      <cascader-common
                        v-model:modelValue="item.costTypeId"
                        placeholder="请选择费用类型"
                        :options="paymentTypeOptions"
                        :casProps="casProps"
                        :disabled="dealDisabled"
                        style="width: 190px"
                      />
                    </td>
                    <!-- 合同优惠 -->
                    <td style="width: 10%">
                      <qzf-input style="width: 70px" v-model:num="item.contractDiscount" :disabled="dealDisabled" @blur="changeCycle(item)" />
                      <!-- <el-input
                        size="small"
                        style="width: 70px"
                        :disabled="dealDisabled"
                        v-model="item.contractDiscount"
                        @blur="changeContract(item,index)"
                      /> -->
                    </td>
                    <!-- 应收金额 -->
                    <td style="width: 110%">
                      <qzf-input style="width: 70px" v-model:num="item.contractAmount" :disabled="dealDisabled" />
                      <!-- <el-input
                        size="small"
                        style="width: 70px"
                        :disabled="dealDisabled"
                        v-model="item.contractAmount"
                      /> -->
                    </td>
                    <!-- 备注 -->
                    <td style="width: 5%">
                      <el-input
                        v-model="item.remark"
                        size="small"
                        style="width: 100px"
                        :disabled="dealDisabled"
                        placeholder="请输入备注"
                      />
                    </td>
                    <!-- 合同编码 -->
                    <td style="width: 5%">
                      <el-input
                        size="small"
                        style="width: 70px"
                        :disabled="dealDisabled"
                        v-model="item.contractNum"
                      />
                    </td>
                  </tr>
                  <tr v-else>
                    <td style="text-align: center" colspan="24">
                      <div>
                        <span style="font-size: 12px;color: #AAAAAA;">暂无相关数据，点击下方按钮快速新增</span>
                        <div
                          style="
                            color: var(--themeColor, #17a2b8);
                            cursor: pointer;
                            z-index: 9999;
                            position: relative;
                          "
                          @click="addContract(form.contract)"
                        >
                          +添加合同信息
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">
              <template #title>
                <div class="formTitle margin20">
                  合同附件
                </div>
              </template>
              <FileUploader :dis="dealDisabled || dealType == 'yes'? true:false" @change="updateFileList" :accept="'.jpg, .png, .jpeg'" :initialFileList="form.fileLists" />
              <!-- <el-form-item label="代账合同:" class="disIcon" prop="dzAttachment" v-if="form.serviceType == 1">
                <imgupload :disabled="dealDisabled" :fileListTemp="form.dzAttachment" @updatet="updateFileList" @del="delFileList" style="width: 600px;"></imgupload>
              </el-form-item>
              <el-form-item label="增值合同:" class="disIcon" prop="zzAttachment" v-else>
                <imgupload :disabled="dealDisabled" :fileListTemp="form.zzAttachment" @updatet="updateFileList2" @del="delFileList2" style="width: 600px;"></imgupload>
              </el-form-item> -->
            </el-collapse-item>
            <!-- <el-collapse-item name="4">
              <template #title>
                <div class="formTitle margin20">
                  客户资料
                </div>
              </template>
              <div>
                <table class="content" cellspacing="0" width="100%">
                  <tr class="top_bg center">
                    <td style="width: 10%;height: 40px;text-align: center;">资料名称</td>
                    <td style="width: 14%;height: 40px;text-align: center;">资料类型</td>
                    <td style="width: 11%;height: 40px;text-align: center;">资料件数</td>
                    <td style="width: 21%;height: 40px;text-align: center;">开始期间</td>
                    <td style="width: 21%;height: 40px;text-align: center;">结束期间</td>
                    <td style="width: 12%;height: 40px;text-align: center;">备注</td>
                    <td style="width: 11%;height: 40px;text-align: center;">操作</td>
                  </tr>
                  <tr
                    class="centers"
                    v-for="(item, index) in form.information"
                    :key="index"
                    v-if="form.information.length > 0"
                  >
                    <td style="width: 10%">
                      <el-input
                        v-model="item.contractAmount"
                        size="small"
                        style="width: 110px"
                        placeholder="请选择资料"
                        readonly
                      >
                        <template #append>
                          <el-icon @click="selectInfo"><Search /></el-icon>
                        </template>
                      </el-input>
                    </td>
                    <td style="width: 14%">
                      <el-select
                        v-model="item.infoType"
                        placeholder="请选择资料类型"
                        size="small"
                        style="width: 75px"
                        @change="changeDisable(item)"
                        :disabled="dealDisabled"
                      >
                        <el-option
                          v-for="item in informationTypeList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </td>
                    <td style="width: 11%">
                      <el-input
                        size="small"
                        style="width: 70px"
                        v-model="item.quantity"
                        :disabled="dealDisabled"
                      />
                    </td>
                    <td style="width: 18%">
                      <el-date-picker
                        type="month"
                        placeholder="请选择开始时间"
                        size="small"
                        style="width: 100%"
                        v-model="item.startAt"
                        :disabled="dealDisabled"
                      />
                    </td>
                    <td style="width: 18%">
                      <el-date-picker
                        type="month"
                        placeholder="请选择结束时间"
                        size="small"
                        style="width: 100%"
                        v-model="item.endAt"
                        :disabled="dealDisabled"
                      />
                    </td>
                    <td style="width: 16%">
                      <el-input
                        v-model="item.remark"
                        size="small"
                        style="width: 100%"
                        placeholder="请输入备注"
                        :disabled="dealDisabled"
                      />
                    </td>
                    <td style="width: 11%;">
                      <div style="display: flex;align-items: center;height: 20px;line-height: 20px;justify-content: center;">
                        <el-icon color="#409efc" style="margin-right: 6px;cursor: pointer;" @click="addCustomer(form.information)"><Plus /></el-icon> |
                        <el-icon color="#409efc" style="margin-left: 6px;cursor: pointer;" @click="delCustomer(form.information, index)"><Minus /></el-icon>
                      </div>
                    </td>
                  </tr>
                  <tr v-else>
                    <td style="text-align: center" colspan="7">
                      <span style="font-size: 12px;color: #AAAAAA;">暂无相关数据，点击下方按钮快速新增</span>
                      <div
                        style="
                          color: var(--themeColor, #17a2b8);
                          cursor: pointer;
                          z-index: 9999;
                          position: relative;
                        "
                        @click="addCustomer(form.information)"
                      >
                        +添加客户资料
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </el-collapse-item> -->
          </el-collapse>
        </el-form>
      </template>
    </el-drawer>
  </div>

  <!-- 选择弹窗 -->
  <selectinformation ref="selectinformation" @success="getInfo"></selectinformation>
</template>

<script>
import imgupload from "./imgUpload.vue"//成交线索抽屉
import { INFORMATION,TRAXPAYER,COLLECTION_CYCLE,INFORMATION_TYPE } from "@/utils/commonData";
import selectinformation from "./selectInformation.vue"
import selectBusiness from "../../../crm/components/selectBusiness.vue";
import { categoryListNew, } from "@/api/crm";
import { getCurrentDateFormatted } from '@/utils'
import { contractTypeList,deal,dealInfo } from "@/api/newCrm.js";
import FileUploader from "@/components/uploadImg/FileUploader.vue";
import {
  getMonthFirstAndLastDay,
  getEndDate,
  getMonthsDifference,
} from "@/utils/fun";

export default {
  name: '',
  components: {
    imgupload,
    selectinformation,
    selectBusiness,
    FileUploader
  },
  props: {
    dealDisabled:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cycleOptions: COLLECTION_CYCLE,
      selectedValues: [], // 存储选中的值 
      addDrawer: false,//抽屉
      activeNames: ['1','2','3'],
      form: {
        contract:[],
        fileLists: [],
        fileList:'',
      },
      rules: {
        customerName: [
          { required: true, message: '请输入客户名称', trigger: 'blur' },
        ],
        taxpayerRight: [
          { required: true, message: '请选择纳税人资格', trigger: "change" }
        ],
        serviceType: [
          { required: true, message: '请选择服务类型', trigger: "change" }
        ],
        customerContacter: [
          { required: true, message: '请输入客户联系人', trigger: "blur" }
        ],
        customerContacterMobile: [
          { required: true, message: '请输入联系人手机', trigger: "blur" }
        ],
        totalAmount: [
          { required: true, message: '请填写合同信息', trigger: "blur" }
        ],
        dealUserId: [
          { required: true, message: '请选择签单人员', trigger: "change" }
        ],
        bossTel: [
          { required: true, message: '请选择老板手机号', trigger: "change" }
        ],
        dzAttachment: [
          { required: true, message: '请上传合同附件', trigger: "change", validator: this.validateAttachment }
        ],
        zzAttachment: [
          { required: true, message: '请上传合同附件', trigger: "change", validator: this.validateAttachment }
        ],
      },
      taxpayerList: TRAXPAYER,//纳税人资格
      dealType: '',//已成交部分选项禁掉
      dataType: INFORMATION,
      categoryList: [],
      contractTypeOptions:[],//业务类型
      paymentTypeOptions:[],//费用类型
      casProps:{
        expandTrigger: "hover",
        label: "name",
        value: "id",
      },
      cycleOptionsTemp:[
        { label: '一次性', value: 1 },
        { label: '月', value: 1 },
        { label: '季', value: 3 },
        { label: '半年', value: 6 },
        { label: '年', value: 12 },
        { label: '2年', value: 24 },
      ],
      informationTypeList: INFORMATION_TYPE,
      contractTypeOptions:[],
      saveReqLoading: false,
    }
  },
  computed: {},
  watch: {
    'form.contract': {
      handler(newVal, oldVal) {
        // 表单数据发生变化时，需要做的事情
        this.form.totalAmount = 0
        if(newVal){
          newVal.forEach(item => {
            // 累加到 all
            this.form.totalAmount += item.contractAmount;
            item.giveNum = item.giveNum*1
            item.accountBookFee = item.accountBookFee*1
            item.serviceCharge = item.serviceCharge*1
            item.contractDiscount = item.contractDiscount*1
            item.paymentNum = item.paymentNum*1
          });
        }
      },
      deep: true
    }
  },
  created () {
    this.getBussList()
    this.getPayList()
    // this.defaultExpandedKeys = this.dataType.map(item => item.value);  
  },
  mounted () {
  },
  methods: {
    init(row,type){
      if(type == 'view'){
        dealInfo({id:row.id}).then(res => {
          if(res.data.msg == 'success'){
            this.form = res.data.data.data
            this.changeType()
            if (this.form.fileList) {
              let arr = this.form.fileList.split(",")
              this.form.fileLists = arr.map(v => {
                const ext = v.split(".").pop();
                return {
                  name: v.split("/").pop(),
                  url: v,
                  type: ext,
                }
              });
            }
          }
        })
      }else{
        this.form = {...row}
        this.form.information = [],
        this.form.contract = []
        this.form.id = null
        this.form.clueId = row.id
        this.form.dealUserId = this.$store.getters['user/user'].userId
        this.form.contractType = row.serviceType
        if(!this.form.serviceType){
          this.form.serviceType = 1
        }
        this.form.dealAt = getCurrentDateFormatted()
        this.changeType()
      }
      this.addDrawer = true;
      // this.form.totalAmount = 0
    },
    //  业务类型
    getBussList(type){
      contractTypeList({ contractType: type }).then((res) => {
        this.contractTypeOptions = res.data.data.list;
      });
    },
    // 费用类型
    getPayList(){
      contractTypeList({ contractType: '3' }).then((res) => {
        this.paymentTypeOptions = res.data.data.list;
      });
    },
    // 服务类型切换
    changeType(){
      let type =  this.form.serviceType == 1  ? '1' : '2';
      this.getBussList(type)
    },
    handleChange(val) {
      // 如果尝试关闭第一个 item，则重新打开它  
      if (!val.includes('1')) {  
        this.activeNames.push('1')  
      } else {  
        // 允许其他 item 的状态改变  
        this.activeNames = val;  
      }  
    },
    validateAttachment(rule, value, callback) {
      if (!value) {
        if (this.form.serviceType == 1) {
          if (!this.form.dzAttachment) {
            callback(new Error('请上传代账合同'))
            return
          }
        } else if (this.form.serviceType == 2) {
          if (!this.form.zzAttachment) {
            callback(new Error('请上传增值合同'))
            return
          }
        }
      } else {
        callback()
      }
    },
    saveCule(){
      // 保存成交线索
      let sfSave = true
      if(!this.form.contract){
        this.$message.warning('合同信息不能为空，请填写合同信息');
        return
      }else{
        this.form.contract.forEach(val=>{
          if(!val.costTypeId){
            sfSave = false
          }
          if(!val.businessTypeId){
            sfSave = false
          }
          if(!val.serviceCharge){
            sfSave = false
          }
          if(!val.contractAmount){
            sfSave = false
          }
        })
      }
      if(sfSave){
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.$confirm('确定保存吗？保存后会自动生成客户档案、代账合同', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              if(this.form.followUserId){
                this.form.followUserId = this.form.followUserId*1
              }else{
                this.form.followUserId = null 
              }
              this.form.totalAmount = this.form.totalAmount*1
              this.form.dealUserId = this.form.dealUserId*1
              let temp =[]
              this.form.fileLists?.forEach(i=>{
                temp.push(i.url)
              })
              this.form.fileList = temp.toString()
              this.saveReqLoading = true
              deal(this.form).then((res) => {
                this.saveReqLoading = false
                if (res.data.msg == "success") {
                  this.$message.success('保存成功');
                  this.addDrawer = false
                  this.$emit('success')
                }
              }).catch(() => {
                this.saveReqLoading = false
              });
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }else{
        this.$message.warning('请检查合同信息是否填写完');
      }
    },
    // updateFileList(url) {
    //   this.form.dzAttachment = url.toString()
    //   // 处理上传成功后的逻辑，例如更新表单字段
    // },
    // delFileList(url){
    //   this.form.dzAttachment = url.toString()
    // },
    // updateFileList2(url) {
    //   this.form.zzAttachment = url.toString()
    //   // 处理上传成功后的逻辑，例如更新表单字段
    // },
    // delFileList2(url){
    //   this.form.zzAttachment = url.toString()
    // },
    //上传合同附件
    updateFileList(e){
      this.form.fileLists = e
    },
    //添加客户资料
    addCustomer(item) {
      item.push({
        categoryNameId: null,
        paymentCycle: "1",
        contractStartTime: "",
        contractEndTime: "",
        contractAmount: "",
      });
    },
    //删除客户资料
    delCustomer(item, index) {
      // if (item.length <= 1) {
      //   this.$message.error("仅剩一项禁止删除");
      //   return;
      // }
      item.splice(index, 1);
    },

    //添加合同信息
    addContract(item) {
      const { firstDay, lastDay } = getMonthFirstAndLastDay();
      item.push({
        businessTypeId:null,
        contractStartTime:firstDay,
        contractEndTime: lastDay,
        paymentCycle: '一次性',
        paymentNum: 1,
        giveNum: 0,
        serviceCharge: 0,
        accountBookFee: 0,
        costTypeId: null,
        contractDiscount: 0,
        contractAmount: 0,
        remark:'',
        contractNum:'',
      });
    },
    //删除合同信息
    delContract(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    calculateEndDate(item) {
      const startDate = new Date(item.contractStartTime);
      let monthsToAdd = 0;
      if (item.paymentCycle === '一次性') {
        // item.contractEndTime = ''; // 直接设置为开始日期
      } else {
        switch (item.paymentCycle) {
          case '月':
            monthsToAdd = item.paymentNum + item.giveNum;
            break;
          case '季':
            monthsToAdd = item.paymentNum * 3 + item.giveNum;
            break;
          case '半年':
            monthsToAdd = item.paymentNum * 6 + item.giveNum;
            break;
          case '年':
            monthsToAdd = item.paymentNum * 12 + item.giveNum;
            break;
          case '2年':
            monthsToAdd = item.paymentNum * 24 + item.giveNum;
            break;
        }
        startDate.setMonth(startDate.getMonth() + monthsToAdd);
        item.contractEndTime = startDate.toISOString().split('T')[0];
      }
    },
    calculateMoney(item) {
      let monthsToAdd = 0;
      switch (item.paymentCycle) {
        case '一次性':
          monthsToAdd = item.paymentNum * item.serviceCharge;
          break;
        case '月':
          monthsToAdd = item.paymentNum * item.serviceCharge;
          break;
        case '季':
          monthsToAdd = item.paymentNum * item.serviceCharge * 3;
          break;
        case '半年':
          monthsToAdd = item.paymentNum * item.serviceCharge * 6;
          break;
        case '年':
          monthsToAdd = item.paymentNum * item.serviceCharge * 12;
          break;
        case '2年':
          monthsToAdd = item.paymentNum * item.serviceCharge * 24;
          break;
      }
      item.contractAmount = monthsToAdd + item.accountBookFee - item.contractDiscount
    },

    firstDisabledDate(time){
      const date = time.getDate();
      return date !== 1;
    },
    // 开始时间、结束时间判断
    lastDisabledDate(time){
      const date = time.getDate();
      const lastDay = new Date(
        time.getFullYear(),
        time.getMonth() + 1,
        0
      ).getDate();
      return date !== lastDay;
    },


    // 收款方式
    // changeCycle(item,i){
    //   if(!item.paymentNum){
    //     item.paymentNum = 1;
    //   }
    //   if(!item.giveNum){
    //     item.giveNum = 0;
    //   }
    //   const now = new Date();
    //   if(!item.contractStartTime){
    //     item.contractStartTime = now.toISOString().split('T')[0];
    //   } 
    //   this.calculateEndDate(item);
    // },
    //修改收款各项
    changeCycle(value){
      if (value.paymentCycle == "一次性") {
        value.paymentNum = 1;
      }else{
        value.contractEndTime = getEndDate(
          value.contractStartTime,
          value.paymentCycle,
          value.paymentNum,
          value.giveNum
        );
      }
      this.changeServiceCharge(value);
    },
    //修改服务费
    changeServiceCharge(value){
      let monthPoor = getMonthsDifference(
        value.contractStartTime,
        value.contractEndTime
      );
      let total = monthPoor * 1 - value.giveNum;
      let amount = value.serviceCharge
        ? value.serviceCharge * this.filterCycle(value)
        : 0
      value.contractAmount = value.serviceCharge
        ? Number((total * value.serviceCharge).toFixed(2)) + value.accountBookFee - value.contractDiscount
        : 0;
    },
    filterCycle(value){
      let monthsToAdd = 1;
      switch (value.paymentCycle) {
        case "季":
          monthsToAdd = 3;
          break;
        case "半年":
          monthsToAdd = 6;
          break;
        case "年":
          monthsToAdd = 12;
          break;
        case "2年":
          monthsToAdd = 24;
          break;
      }
      return monthsToAdd;
    },

    // 收款周期
    changeNum(item) {
      this.changeServiceCharge(item)
    },
    // 赠送月份
    changeGive(val,i){
      this.calculateEndDate(val);
    },
    // 月服务费
    changeService(val,i){
      // 计算合同信息每一条应收金额
      this.calculateMoney(val);
    },
    // 账本费
    changeAccount(val,i){
      // 计算合同信息每一条应收金额
      this.calculateMoney(val);
    },
    // 合同优惠
    changeContract(val,i){
      // 计算合同信息每一条应收金额
      this.calculateMoney(val);
    },
    // 选择资料名称
    selectInfo(){
      this.$refs.selectinformation.init()
    },
    // 资料获取
    getInfo(data,ids){
      this.form.information = this.form.information.filter(i => i.contractAmount !== '');
      data.forEach(item => {
        this.form.information.push({
          contractAmount: item.label,
          templateID: item.id,
          quantity: null,
          startAt: "",
          endAt: "",
          remark: '',
          infoType: 1,
        })
      });
    },
    // 业务类型相关操作
    getOptions() {
      // 初始化 options
      this.options = [];

      // 确保 categoryList 和 form.server 都存在
      if (!this.categoryList || !this.form.server) return;

      // 创建一个映射表用于快速查找
      const serverMap = new Map();
      this.form.server.forEach((item) => {
        if (!item.id) {
          // 设置 paymentCycle 属性
          item.paymentCycle = item.categoryNameId === 5 ? "0" : "1";
        }
        // 使用 Map 存储 id 对应的 item
        serverMap.set(item.categoryNameId, item);
      });

      // 遍历 categoryList 并添加符合条件的子项
      this.categoryList.forEach((v) => {
        v.children.forEach((k) => {
          if (serverMap.has(k.id)) {
            this.options.push(k);
          }
        });
      });

      // 去重
      this.options = [...new Set(this.options.map((option) => option.id))].map(
        (id) => {
          return this.options.find((option) => option.id === id);
        }
      );
    },

    getCategory() {
      categoryListNew({}).then((res) => {
        if (res.data.msg == "success") {
          this.categoryList = res.data.data.list ? res.data.data.list : [];
          this.getOptions();
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
.formTitle{
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 14px;
}
.w200{
  width: 200px;
}
.drawerHead{
  display: flex;
  justify-content: space-between;
}
.up{
  margin: 2px 0 0 3px;
  transform: rotate(-90deg);  
  cursor: pointer;
  z-index: 9999;
}
.down{
  margin: 2px 0 0 3px;
  transform: rotate(-90deg); 
  cursor: pointer; 
  z-index: 9999;
}
:deep(.el-collapse){
  border: none;
}
:deep(.el-collapse-item__arrow){
  margin: 0 8px 0 5px!important;
}
:deep(.el-collapse-item:first-of-type .el-collapse-item__header .el-collapse-item__arrow) {  
  display: none;  
}
.margin20{
  margin-top: 20px;
}
.content {
  width: 100%;
  overflow-x: auto; /* 启用水平滚动条 */  
  white-space: nowrap; /* 防止内容换行 */ 
  min-height: 72px;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  span {
    line-height: 28px;
    font-size: 13px;
  }
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    height: 32px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}
.centers {
  text-align: center;
  height: 30px;
  width: 100%;
}
:deep(.el-input-group__append, .el-input-group__prepend){
  padding: 0 6px;
  cursor: pointer;
}
.ht :deep(.el-collapse-item__wrap){
  overflow-x: auto!important;
}
.scroll-container{
  overflow-x: auto; /* 允许水平滚动 */  
  max-width: 100%; 
  position: relative;
}
.no-data-container {  
  position: fixed; /* 固定在视口 */  
  top: 50%; /* 垂直居中 */  
  left: 50%; /* 水平居中（需要配合 transform） */  
  transform: translate(-50%, -50%); /* 调整位置以正确居中 */  
  text-align: center; /* 文本居中 */  
  width: 100%; /* 或其他适当的宽度，但这里使用 100% 会导致水平居中失效，需要调整 */  
}  
</style>
