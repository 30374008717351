export const ssflbmOptions = [
  {
    label: '货物',
    value: "10",
  },
  {
    label: '劳务',
    value: "20",
  },
  {
    label: '销售服务',
    value: "30",
  },
  {
    label: '无形资产',
    value: "40",
  },
  {
    label: '不动产',
    value: "50",
  },
  {
    label: '未发生销售行为的不征税项目',
    value: "60",
  },
]